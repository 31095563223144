import { AuthenticationResult, Configuration, PopupRequest, PublicClientApplication } from "@azure/msal-browser";
import { WEB_APPLICATION_ID } from "../constants";

export async function authenticateUser() {
    const msalConfig: Configuration = {
        auth: {
            clientId: WEB_APPLICATION_ID,
            authority: "https://login.microsoftonline.com/common",
            redirectUri: "https://" + window.location.host + "/activator",
        }
    };

    const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);


    const popupRequest: PopupRequest = {
        scopes: ["api://" + window.location.host + "/" + WEB_APPLICATION_ID + "/access_as_user"],
        prompt: "select_account"
    };

    const authResult: AuthenticationResult = await msalInstance.acquireTokenPopup(popupRequest);
    return authResult as AuthenticationResult;
}
import Activator from "./components/Activator/Activator";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import React from "react";
import StyleEditor from "./components/StyleEditor/StyleEditor";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/activator" element={<Activator/>} />
                    <Route path="/style-editor" element={<StyleEditor/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

import {
    AuthError
} from "@azure/msal-browser";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { API_ENDPOINT_ORGANIZATION_STYLE } from "../../constants";
import { authenticateUser } from '../../utils/userAuth';
import './StyleEditor.scss';

export default function StyleEditor() {
    const SAVE_AS_NEW_OPTION = "save-as-new";

    const [userData, setUserData] = useState<UserData>();
    const [currentSelectedId, setCurrentSelectedId] = useState<string>(SAVE_AS_NEW_OPTION);
    const [currentSelectedName, setCurrentSelectedName] = useState<string>("");
    const [currentSelectedScope, setCurrentSelectedScope] = useState<string>("");
    const [currentSelectedIsDefault, setCurrentSelectedIsDefault] = useState<boolean>(false);
    const [currentSelectedStyleJSON, setCurrentSelectedStyleJSON] = useState<string>("");

    const [availableStyles, setAvailableStyles] = useState<StyleData[]>();
    const [responseMessage, setResponseMessage] = useState<string>();
    const responseMessageRef = useRef<HTMLSpanElement>(null);


    useEffect(() => {
        if (!userData) {
            return;
        }
        loadAvailableStyles();
    }, [userData]);


    function loadAvailableStyles() {
        if (!userData) {
            return;
        }
        // Fetch available styles
        const availableStylesUrl = `${API_ENDPOINT_ORGANIZATION_STYLE}/available/${userData.tenantId}/${userData.objectId}`
        fetch(availableStylesUrl, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${userData.accessToken}`
            }
        }).then((res) => {
            res.json().then((jsonData) => {
                const styles = jsonData as StyleData[];
                setAvailableStyles(styles);
            }).catch((err) => console.error(err));

        }).catch((err) => console.error(err));
    }

    function msalSignIn(e: React.MouseEvent<Element, MouseEvent>) {
        e.preventDefault();

        try {
            authenticateUser()
                .then((authResult) => {
                    setUserData({
                        tenantId: authResult.tenantId,
                        objectId: authResult.uniqueId,
                        accessToken: authResult.accessToken
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        } catch (error) {
            if (error instanceof AuthError) {
                console.log("User interaction is needed to get a token");
            }
            console.error("Error logging in", error);
        }

        return "";
    }

    function saveStyle(event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        if (!userData || !userData.tenantId || !userData.objectId) {
            return;
        }

        const formElement = event.target as HTMLFormElement;

        const styleSelectElement = formElement.elements.namedItem("styleSelect") as HTMLInputElement;
        const selectedStyleId = styleSelectElement.value;

        const nameInputElement = formElement.elements.namedItem("styleName") as HTMLInputElement;
        const jsonTextAreaElement = formElement.elements.namedItem("styleJSON") as HTMLTextAreaElement;
        const scopeSelectElement = formElement.elements.namedItem("scopeSelect") as HTMLSelectElement;
        const isDefaultInputElement = formElement.elements.namedItem("isDefault") as HTMLInputElement;


        if (selectedStyleId === SAVE_AS_NEW_OPTION) {
            // Create new style
            const styleData: StyleData = {
                msftTenantId: userData.tenantId,
                name: nameInputElement.value,
                styleJSON: jsonTextAreaElement.value,
                scope: scopeSelectElement.value as StyleScope,
                isDefault: isDefaultInputElement?.checked === true,
                msftObjectId: userData.objectId
            };

            const styleUrl = `${API_ENDPOINT_ORGANIZATION_STYLE}/create-style`;
            fetch(styleUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userData.accessToken}`
                },
                body: JSON.stringify(styleData)
            }).then((res) => {
                if (res.ok) {
                    showMessage("Style created.")
                    res.json().then((jsonData) => {
                        loadAvailableStyles();
                        setCurrentSelectedId(jsonData.id);
                    });
                } else {
                    showMessage(`Failed to create style. (${res.status})`)
                }
            }).catch((err) => {
                showMessage(`Failed to create style. (${err})`)
                console.error(err);
            });
        } else {
            // Update existing style
            const styleData: StyleData = {
                id: parseInt(selectedStyleId),
                msftTenantId: userData.tenantId,
                name: nameInputElement.value,
                styleJSON: jsonTextAreaElement.value,
                scope: scopeSelectElement.value as StyleScope,
                isDefault: isDefaultInputElement?.checked === true,
                msftObjectId: userData.objectId
            };

            const styleUrl = `${API_ENDPOINT_ORGANIZATION_STYLE}/update-style`;
            fetch(styleUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userData.accessToken}`
                },
                body: JSON.stringify(styleData)
            }).then((res) => {
                if (res.ok) {
                    showMessage("Style updated.")
                } else {
                    showMessage(`Failed to update style. (${res.status})`)
                }
            }).catch((err) => {
                showMessage(`Failed to update style. (${err})`)
                console.error(err);
            });
        }

    }


    function deleteStyle(event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        if (!userData) {
            return;
        }

        const styleUrl = `${API_ENDPOINT_ORGANIZATION_STYLE}/delete-style`;
        fetch(styleUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userData.accessToken}`
            },
            body: JSON.stringify({
                id: currentSelectedId,
                msftTenantId: userData.tenantId
            })
        }).then((res) => {
            if (res.ok) {
                showMessage("Style deleted.")
                loadAvailableStyles();
                changeCurrentStyle(SAVE_AS_NEW_OPTION);
            } else {
                showMessage(`Failed to delete style. (${res.status})`)
            }
        }).catch((err) => {
            showMessage(`Failed to delete style. (${err})`)
            console.error(err);
        });
    }

    function isValidCurrentJSON() {
        if (!currentSelectedStyleJSON) {
            return false;
        }

        try {
            JSON.parse(currentSelectedStyleJSON);
        } catch (e) {
            return false;
        }
        return true;
    }

    function submitStyleForm(event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        event.preventDefault();
        if (!userData) {
            return;
        }

        const eventName = event.nativeEvent.submitter?.name;

        if (eventName === "save") {
            if (!isValidCurrentJSON()) {
                showMessage("Error: Invalid JSON format.");
                return;
            }
            saveStyle(event);
        } else if (eventName === "delete") {
            deleteStyle(event);
        }
    }

    function showMessage(messageText: string) {
        const messageElement = responseMessageRef.current;
        if (!messageElement) {
            return;
        }

        messageElement.style.opacity = "1";
        setTimeout(() => {
            messageElement.style.opacity = "0";
        }, 2000);

        setResponseMessage(messageText);
    }

    function changeCurrentStyle(selectedStyleId: string): void {
        if (!userData) {
            return;
        }

        setCurrentSelectedId(selectedStyleId);
        if (selectedStyleId === SAVE_AS_NEW_OPTION) {
            setCurrentSelectedScope("");
            setCurrentSelectedName("");
            setCurrentSelectedIsDefault(false);
            setCurrentSelectedStyleJSON("");
        } else {
            const availableStylesUrl = `${API_ENDPOINT_ORGANIZATION_STYLE}/id/${selectedStyleId}`
            fetch(availableStylesUrl, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${userData.accessToken}`
                }
            }).then((res) => {
                res.json().then((jsonData) => {

                    const style: StyleData = {
                        id: jsonData.id,
                        msftTenantId: jsonData.msftTenantId,
                        name: jsonData.name,
                        styleJSON: JSON.stringify(jsonData.styleJSON, null, 4),
                        scope: jsonData.scope,
                        isDefault: jsonData.isDefault,
                        msftObjectId: jsonData.msftObjectId
                    }

                    setCurrentSelectedScope(style.scope);
                    setCurrentSelectedName(style.name);
                    setCurrentSelectedIsDefault(style.isDefault);
                    setCurrentSelectedStyleJSON(style.styleJSON);

                }).catch((err) => console.error(err));

            }).catch((err) => console.error(err));
        }
    }


    function styleEditorElements() {

        let availableStylesOptions = null;
        if (availableStyles) {
            availableStylesOptions = availableStyles.map((styleData) => {
                return <option value={styleData.id} key={styleData.id}>{styleData.name}</option>
            });
        }

        return (
            <form onSubmit={submitStyleForm}>
                <h2>Style editor</h2>

                <label>Choose the style to edit</label>
                <select
                    name="styleSelect"
                    value={currentSelectedId}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => { changeCurrentStyle(event.target.value) }}
                >
                    <option value={SAVE_AS_NEW_OPTION}>New style</option>
                    {availableStylesOptions}
                </select>


                <label>Paste your JSON here:</label>
                <textarea
                    name="styleJSON"
                    rows={20} cols={100}
                    value={currentSelectedStyleJSON}
                    onChange={(e) => setCurrentSelectedStyleJSON(e.target.value)}
                    required
                >
                </textarea>

                <label>Name your style:</label>
                <div className="style-properties">
                    <div className="flex-row">
                        <input
                            name="styleName" type="text"
                            placeholder="Style name"
                            value={currentSelectedName}
                            onChange={(e) => setCurrentSelectedName(e.target.value)}
                            required
                        />

                        <select
                            name="scopeSelect"
                            value={currentSelectedScope}
                            onChange={(e) => setCurrentSelectedScope(e.target.value)}
                            required
                        >
                            <option value="" disabled hidden>Select scope</option>
                            <option value="personal">Personal</option>
                            <option value="global">Global</option>
                        </select>
                    </div>
                    {
                        currentSelectedScope == "global" &&
                        <div className="flex-row">
                            <input
                                name="isDefault"
                                type="checkbox"
                                checked={currentSelectedIsDefault}
                                onChange={(e) => setCurrentSelectedIsDefault(e.target.checked)}
                            />
                            <span>Default corporate branding style</span>
                        </div>
                    }
                </div>

                <div className="style-editor-controls">
                    <button type="submit" value="Submit" name="save">Save style</button>
                    {
                        currentSelectedId !== SAVE_AS_NEW_OPTION &&
                        <button className="delete-button" type="submit" name="delete">Delete style</button>
                    }
                    <span ref={responseMessageRef} className="response-message">{responseMessage}</span>
                </div>
            </form>
        )
    }

    return (
        <div className="style-editor">
            {userData ?
                styleEditorElements()
                :
                <button onClick={msalSignIn}>
                    {"Sign In"}
                </button>
            }
        </div>
    );
}


interface UserData {
    tenantId?: string,
    objectId?: string,
    accessToken?: string
}

type StyleScope = "global" | "personal";

interface StyleData {
    id?: number;
    msftTenantId: string;
    name: string;
    styleJSON: string;
    scope: StyleScope;
    isDefault: boolean;
    msftObjectId: string;
}
import React from "react";
import Header from "../Header/Header";

function Home() {
    return (
        <Header></Header>
    );
}

export default Home;

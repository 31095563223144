import {
    AuthError
} from "@azure/msal-browser";
import React, { useState } from 'react';
import {
    ACTIVATION_CODE_ALREADY_EXIST,
    API_ENDPOINT_ACTIVATION
} from "../../constants";
import { authenticateUser } from '../../utils/userAuth';
import './Activator.scss';

function Activator() {
    const [result, setResult] = useState(({} as LicenseServerResponse));
    const [user, setUser] = useState({} as User);


    async function activateLicense(accessToken: string) {
        const parameters = new URLSearchParams(window.location.search);
        const refId: string = (parameters?.get("ref_id") as string);

        fetch(API_ENDPOINT_ACTIVATION, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ activationReferenceId: refId })
        })
            .then(response => {
                return response.json().then(result => {
                    if (!response.ok) {
                        setResult({ code: result.code, message: result.message, success: false });
                        throw new Error(result.message);
                    }

                    return result;
                });
            })
            .then((result) => {
                const fullResult = { code: result.code, message: result.message, success: true };
                if (/^https?:\/\/([a-zA-Z\d-]+\.)?zebrabi\.com.*$/.test(document.referrer)) {
                    window.parent.postMessage(JSON.stringify(fullResult), document.referrer);
                }
                setResult(fullResult);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function msalSignIn(e: React.MouseEvent<Element, MouseEvent>) {
        e.preventDefault();
        setResult({ message: undefined, success: undefined });

        try {
            authenticateUser()
                .then((authResult) => {
                    setUser({
                        username: authResult.account?.username,
                        name: authResult.account?.name
                    });
                    activateLicense(authResult.accessToken);
                })
                .catch((e) => {
                    setResult({
                        message: e.message,
                        code: e,
                        success: false
                    });
                });
        } catch (error) {
            if (error instanceof AuthError) {
                console.log("User interaction is needed to get a token");
            }
            console.error("Error logging in", error);
        }

        return "";
    }

    const hasResponse = result.message ?? false;
    return (
        <div className="activator">
            <div className="message">{result.message}</div>

            {result.success &&
                <div className="account">
                    Activated for: {user.username} / {user.name}
                </div>
            }

            {!result.success && result.code !== ACTIVATION_CODE_ALREADY_EXIST &&
                <button onClick={msalSignIn}>
                    {!hasResponse && "Sign In"}
                    {hasResponse && "Retry sign In"}
                </button>
            }
        </div>
    );
}

interface LicenseServerResponse {
    code?: string,
    message?: string
    success?: boolean;
}

interface User {
    username?: string,
    name?: string
}

export default Activator;
